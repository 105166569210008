import styled from "styled-components";
import GlobalStyles from "./GlobalStyles";
import ChatGpt from "./ChatGpt";
import logo from "./logo.svg";
import { motion } from "framer-motion";

function App() {
  return (
    <GptWrapepr>
      <GlobalStyles />
      <ChatGpt />
      <motion.div
        className="jcd"
        initial={{ y: 200 }}
        animate={{ y: 0, transition: { delay: 6, duration: 2 } }}
      >
        <h2>Cette petite blague est une gracieuseté de :</h2>
        <a
          href="https://www.jcesardesign.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={logo}
            alt="Créateur de la blague chat GPT"
          />
        </a>
      </motion.div>
    </GptWrapepr>
  );
}

export default App;

const GptWrapepr = styled.main`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  .jcd {
    font-size: calc(8px + 1.2vw);
    padding-bottom: 2vh;
    position: fixed;
    bottom: 0;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    @media (max-width: 992px) {
      flex-direction: column;
      align-items: center;
    }
    img {
      width: 40vh;
      margin: 0 2vw;
    }
  }
`;
